<template>
    <div class="course" @click="chose">
      <div class="info">
        <div class="status" :class="{ active: course.active }">{{ status }}</div>
        <div class="title">{{ course.title }}</div>
        <p>{{ course.desc }}</p>
      </div>
      <footer>
        <div class="label">Проходят</div>
        <div v-if="users.length" class="users">
          <div v-for="(user, index) of users" :key="index" class="user" :class="{ count: user.count }" :style="{ 'z-index': 5 - index}">
            <template v-if="user.count">
              +{{ user.count }}
            </template>
            <template v-else>
              {{ user.initials }}
            </template>
          </div>
        </div>
        <div v-else class="users-all">
          Не назначен
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CoursesCard',
    props: {
      course: {
        type: Object,
        default: () => {}
      },
    },
    computed: {
      status() {
        return this.course.active ? 'Активен' : 'Отключен';
      },
      users() {
        let { employees } = this.course;

        if (employees.length > 4) {
          employees.unshift({ count: employees.length + 1 });
          employees = employees.slice(0, 4);
        }

        return employees;
      }
    },
    methods: {
      chose() {
        this.$router.replace(`/course/${this.course.id}`)
      }
    },
  }
  </script>
  
  <style lang="scss">
  .course {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 12px;

    .info {
      .status {
        width: max-content;
        border-radius: 100px;
        color: #E26262;
        background: rgba(226, 98, 98, 0.1);
        height: 22px;
        padding: 0 12px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4px;

        &.active {
          background: #E5F0F7;
          color: #006CB7;
        }
      }

      .title {
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 12px;
      }

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        color: #ADADAD;
        margin-bottom: 16px;
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #EDEDED;
      padding: 16px 0 0;

      .label {
        font-size: 14px;
        color: #ADADAD;
      }

      .users-all {
        font-size: 14px;
        color: #006CB7;
        background: #E5F0F7;
        padding: 5px 10px;
        border-radius: 100px;
      }

      .users {
        display: flex;
        align-items: center;

        .user {
          font-size: 12px;
          font-weight: 500;
          border-radius: 50px;
          border: 2px solid #fff;
          width: 32px;
          height: 32px;
          background: #E5F0F7;
          color: #006CB7;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -10px;

          &.count {
            padding-top: 2px;
          }

          img {
            border-radius: 100px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  </style>