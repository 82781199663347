<template>
  <div class="courses">
    <header>
      <div class="search">
        <UIcon name="search" />
        <input type="text" v-model="search" placeholder="Поиск по курсам" />
      </div>
    </header>

    <div class="wrapper">
      <div class="course course-add" @click="create">
        <UIcon name="illustrations/add" />
        <div class="title">Создать новый курс</div>
      </div>

      <Course
        v-for="(course, index) of courses"
        :key="index"
        :course="course"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Course from "@/components/redesign/course/CoursesCard";

export default {
  name: 'Courses',
  components: { Course },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapState('courses', ['courses']),
  },
  methods: {
    ...mapActions('courses', ['init']),
    create() {
      this.$router.replace(`/course/0`);
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss">
.courses {
  width: 100%;

  header {
    margin-bottom: 24px;

    .search {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 8px;
      width: 100%;

      .icon {
        fill: #A9A9A9;
        margin-right: 24px;
      }

      input {
        height: 100%;
        padding: 0;
        border: none;
        font-size: 16px;
      }
    }
  }

  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    .course-add {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;
      background: #fff;
      border-radius: 10px;
      
      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        margin-top: 24px;
      }
    }

    .course {
      cursor: pointer;
      transition: .2s;

      &:hover {
        box-shadow: 0 12px 13px hsl(0deg 0% 77% / 25%);
      }
    }
  }
}
</style>